import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import axios from "axios";

const useRowStyles = makeStyles({
	root: {
		"& > *": {
			borderBottom: "unset",
		},
	},

	ganho: {
		color: "green",
		fontWeight: "bold",
	},

	perda: {
		color: "red",
		fontWeight: "bold",
	},
});

function Row(props) {
	const { row } = props;
	const [open, setOpen] = React.useState(false);
	const classes = useRowStyles();

	return (
		<React.Fragment>
			<TableRow className={classes.root}>
				<TableCell>
					<IconButton
						aria-label="expand row"
						size="small"
						onClick={() => setOpen(!open)}
					>
						{open ? (
							<KeyboardArrowUpIcon />
						) : (
							<KeyboardArrowDownIcon />
						)}
					</IconButton>
				</TableCell>
				<TableCell component="th" scope="row">
					{row.nome_proprietario}
				</TableCell>
				<TableCell align="right">
					{row.valor_deposito.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})}
				</TableCell>
				<TableCell align="right">
					{row.valor_compra.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})}
				</TableCell>
				<TableCell align="right">
					{row.valor_atual.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})}
				</TableCell>
				<TableCell
					align="right"
					className={
						row.valor_atual > row.valor_compra
							? classes.ganho
							: classes.perda
					}
				>
					{row.valor_lucro.toLocaleString("pt-br", {
						style: "currency",
						currency: "BRL",
					})}
				</TableCell>
				<TableCell
					align="right"
					className={
						row.valor_atual > row.valor_compra
							? classes.ganho
							: classes.perda
					}
				>
					{row.percentual_lucro.toLocaleString("pt-br", {
						maximumFractionDigits: 1,
						minimumFractionDigits: 1,
					})}
				</TableCell>
			</TableRow>
			<TableRow>
				<TableCell
					style={{ paddingBottom: 0, paddingTop: 0 }}
					colSpan={7}
				>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Box margin={1}>
							<Typography
								variant="h6"
								gutterBottom
								component="div"
							>
								Ativos
							</Typography>
							<Table size="small" aria-label="purchases">
								<TableHead>
									<TableRow>
										<TableCell>Corretora</TableCell>
										<TableCell>Ativo</TableCell>
										<TableCell align="right">
											Qtd. Ativo
										</TableCell>
										<TableCell align="right">
											Preço Médio
										</TableCell>
										<TableCell align="right">
											Último Preço
										</TableCell>
										<TableCell align="right">
											Comprado
										</TableCell>
										<TableCell align="right">
											Atual
										</TableCell>
										<TableCell align="right">
											Lucro
										</TableCell>
										<TableCell align="right">%</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{row.detalhes.map((historyRow) => (
										<TableRow
											key={
												historyRow.nome_corretora +
												historyRow.nome_ativo
											}
										>
											<TableCell
												component="th"
												scope="row"
											>
												{historyRow.nome_corretora}
											</TableCell>
											<TableCell>
												{historyRow.nome_ativo}
											</TableCell>
											<TableCell align="right">
												{historyRow.quantidade_conta_ativo.toLocaleString(
													"pt-br",
													{
														minimumFractionDigits: 8,
													}
												)}
											</TableCell>
											<TableCell align="right">
												{historyRow.preco_medio_conta_ativo.toLocaleString(
													"pt-br",
													{
														style: "currency",
														currency: "BRL",
													}
												)}
											</TableCell>
											<TableCell
												align="right"
												className={
													historyRow.ultimo_preco_ativo_corretora >
													historyRow.preco_medio_conta_ativo
														? classes.ganho
														: classes.perda
												}
											>
												{historyRow.ultimo_preco_ativo_corretora.toLocaleString(
													"pt-br",
													{
														style: "currency",
														currency: "BRL",
													}
												)}
											</TableCell>
											<TableCell align="right">
												{historyRow.preco_compra.toLocaleString(
													"pt-br",
													{
														style: "currency",
														currency: "BRL",
													}
												)}
											</TableCell>
											<TableCell align="right">
												{historyRow.preco_atual.toLocaleString(
													"pt-br",
													{
														style: "currency",
														currency: "BRL",
													}
												)}
											</TableCell>
											<TableCell
												align="right"
												className={
													historyRow.preco_atual -
														historyRow.preco_compra >=
													0
														? classes.ganho
														: classes.perda
												}
											>
												{(
													historyRow.preco_atual -
													historyRow.preco_compra
												).toLocaleString("pt-br", {
													style: "currency",
													currency: "BRL",
												})}
											</TableCell>
											<TableCell
												align="right"
												className={
													historyRow.preco_atual -
														historyRow.preco_compra >=
													0
														? classes.ganho
														: classes.perda
												}
											>
												{(
													((historyRow.preco_atual -
														historyRow.preco_compra) /
														historyRow.preco_compra) *
													100.0
												).toLocaleString("pt-br", {
													maximumFractionDigits: 1,
													minimumFractionDigits: 1,
												})}
											</TableCell>
										</TableRow>
									))}
									{/* <TableRow>
										<TableCell colSpan={5} />
										<TableCell>
											<b>TOTAL</b>
										</TableCell>
										<TableCell align="right">
											<b>R$ 1.234,56</b>
										</TableCell>
										<TableCell />
									</TableRow> */}
								</TableBody>
							</Table>
						</Box>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}

// Row.propTypes = {
// 	row: PropTypes.shape({
// 		calories: PropTypes.number.isRequired,
// 		carbs: PropTypes.number.isRequired,
// 		fat: PropTypes.number.isRequired,
// 		history: PropTypes.arrayOf(
// 			PropTypes.shape({
// 				amount: PropTypes.number.isRequired,
// 				customerId: PropTypes.string.isRequired,
// 				date: PropTypes.string.isRequired,
// 			})
// 		).isRequired,
// 		name: PropTypes.string.isRequired,
// 		price: PropTypes.number.isRequired,
// 		protein: PropTypes.number.isRequired,
// 	}).isRequired,
// };

export default function App() {
	const [rows, setRows] = React.useState([]);

	function requestData() {
		axios
			.get("https://maroguimo.com.br/cripto/resumo.php")
			//.get("http://192.168.0.14/resumo.php")
			.then((response) => {
				setRows(response.data);
			});
	}

	React.useEffect(() => {
		requestData();
		const interval = setInterval(() => {
			requestData();
		}, 30000);
		return () => clearInterval(interval);
	}, []);

	return (
		<TableContainer component={Paper}>
			<Table aria-label="collapsible table" size="small">
				<TableHead>
					<TableRow>
						<TableCell />
						<TableCell>Proprietário</TableCell>
						<TableCell align="right">Depósito</TableCell>
						<TableCell align="right">Comprado</TableCell>
						<TableCell align="right">Atual</TableCell>
						<TableCell align="right">Lucro</TableCell>
						<TableCell align="right">%</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<Row key={row.id_proprietario} row={row} />
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
}
